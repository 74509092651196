import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Spinner from "../../components/Layout/Spinner";
import EditSubject from "../../components/modals/subjects/EditSubjects";
import DeleteSubject from "../../components/modals/subjects/DeleteSubject";

import { Card, Avatar } from "antd";
const { Meta } = Card;

const ResearchItem = ({ subject, clearSubject }) => {
  const { _id, subjectname, image, klass } = subject;

  return !subject ? (
    <Spinner />
  ) : (
    <Card
      style={{ width: "240px", height: "230px" }}
      cover={
        <img
          alt={subjectname}
          src={`https://sis.hipz.edu.ly${image}`}
          hoverable
        />
      }
      actions={[
        <EditSubject savedSubject={subject} />,
        <DeleteSubject id={_id} />,
      ]}
    >
      <Meta
        avatar={
          <Avatar
            style={{ backgroundColor: "blue", verticalAlign: "middle" }}
            size="large"
          >
            {klass}
          </Avatar>
        }
        title={subjectname}
      />
    </Card>
  );
};

ResearchItem.propTypes = {
  research: PropTypes.object.isRequired,
};

export default connect(null)(ResearchItem);
