import React from "react";
import { Link } from "react-router-dom";
import SignUpMember from "../modals/Members/SignUpMember";
import SignUpStudent from "../modals/students/SignUpStudent";
import { Row, Col } from "antd";
const HomePage = () => {
  return (
    <section className="showcase">
      <div className="video-container">
        <video src="/img/scince.mp4" autoPlay muted loop />
      </div>
      <div className="content ">
        <h1 className="center-align ">اختر الشخصية للتسجيل</h1>
        <Row>
          <Col>
            <a
              href="https://sis.hipz.edu.ly/user/register-student"
              target="_blank"
            >
              <div className="links">
                <img
                  src="/img/ibtd.png"
                  alt="ibt"
                  style={{ maxWidth: "420px" }}
                />
                <h3 className="center-align lead text-white lead text-white">
                  طالب / ة
                </h3>
              </div>
            </a>
          </Col>
          <Col>
            <SignUpMember />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HomePage;
