import React, { Fragment } from "react";
import { Divider } from "antd";
import AddSchool from "../../components/modals/School/AddSchool";
import SchoolItems from "./SchoolItems";

const DepartmentItems = ({ schools, user, category, title, color }) => {
  const newSchools = schools.filter((school) => school.category === category);
  return (
    newSchools.length > 0 && (
      <>
        <Divider orientation="left">
          <h1 className={`text-${color}`}>
            {" "}
            {title}{" "}
            {user && user.Admin && (
              <>
                <AddSchool color={color} />
              </>
            )}
          </h1>
        </Divider>
        <div className="container center-align "></div>
        <table className="striped container-fluid ">
          <thead>
            <tr>
              <th className="right-align">الرمز </th>
              <th className="right-align"> العنوان</th>
              <th className="right-align"> رقم الهاتف</th>
              <th className="right-align"> لغة التدريس</th>
              <th className="right-align"> رمز الانتساب</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {schools &&
              schools.map((school) => (
                <>
                  {" "}
                  {school.category === category && (
                    <Fragment key={school._id}>
                      <SchoolItems school={school} />
                    </Fragment>
                  )}
                </>
              ))}
          </tbody>
        </table>
        <br />
      </>
    )
  );
};

export default DepartmentItems;
