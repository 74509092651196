import React, { Fragment, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getCoursesBySchool } from "../../actions/Course.action";
import { getSubjects } from "../../actions/Subjects.action";
import { getMembersBySchool } from "../../actions/Members.actions";
import CourseItems from "./CoursesItems";
import AddCourse from "../../components/modals/courses/AddCourse";
import Spinner from "../../components/Layout/Spinner";
import CoursesFilter from "./CoursesFilter";
import { Row, Col } from "antd";

const CoursePage = ({
  getCoursesBySchool,
  getSubjects,
  getMembersBySchool,
  courses: { courses, filtered, loading },
  school,
  year,
}) => {
  useEffect(() => {
    year && getCoursesBySchool(school._id, year._id);

    getMembersBySchool(school._id);
    getSubjects(school._id);
    // eslint-disable-next-line
  }, [loading]);
  const { user } = useSelector((state) => state.auth);
  return !courses ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid" style={{ direction: "rtl" }}>
        {year &&
          user.departments &&
          user.departments.length > 0 &&
          user.departments.map(
            (s) =>
              s.id._id.toString() === school._id.toString() &&
              s.roles === "1" && (
                <Row gutter={16} xs={10}>
                  <Col md={2}>
                    <AddCourse schoolId={school._id} studyYear={year._id} />
                  </Col>
                  <Col md={10} xs={10}>
                    <CoursesFilter />
                  </Col>
                </Row>
              )
          )}
        {user.roles === "1" && (
          <Row gutter={16} xs={10}>
            <Col md={2}>
              <AddCourse schoolId={school._id} studyYear={year._id} />
            </Col>
            <Col md={10} xs={10}>
              <CoursesFilter />
            </Col>
          </Row>
        )}

        <table className="striped  container-fluid ">
          <thead>
            <tr>
              <th className="right-align"></th>
              <th className="right-align">اسم المقرر</th>
              <th className="right-align"> عدد الوحدات</th>
              <th className="right-align"> استاذ المقرر</th>
              <th className="right-align"></th>
            </tr>
          </thead>
          <tbody>
            {courses && (
              <>
                {filtered && filtered.length > 0
                  ? filtered.map((course) => (
                      <Fragment key={course._id}>
                        <CourseItems course={course} />
                      </Fragment>
                    ))
                  : courses.map((course) => (
                      <Fragment key={course._id}>
                        <CourseItems course={course} />
                      </Fragment>
                    ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  courses: state.courses,
  user: state.auth.user,
  school: state.schools.school,
  year: state.years.year,
});
export default connect(mapStateToProps, {
  getCoursesBySchool,
  getSubjects,
  getMembersBySchool,
})(CoursePage);
