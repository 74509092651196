import axios from "axios";
import {
  GET_SCHOOLS,
  GET_SCHOOL,
  ADD_SCHOOL,
  UPDATE_SCHOOL,
  DELETE_SCHOOL,
  ERROR_SCHOOL,
  CLEAR_DATA,
  UPLOAD_PROGRESS,
  REMOVE_PROGRESS,
} from "./types";

// GET CONFERANCES
export const getSchools = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/departments");
    dispatch({
      type: GET_SCHOOLS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_SCHOOL,
      payload: {
        msg: error.response,
        status: error.response && error.response.status,
      },
    });
  }
};
//  Clear Data
export const clearData = () => (dispatch) =>
  dispatch({
    type: CLEAR_DATA,
  });

// GET CONFERANCES
export const getSchoolByUrl = (url) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/${url}`);
    dispatch({
      type: GET_SCHOOL,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_SCHOOL,
      payload: {
        msg: error.response,
        status: error.response && error.response.status,
      },
    });
  }
};

export const addSchool =
  (file, title, description, Url, languege, email, phone, status, category) =>
  async (dispatch) => {
    const formData = new FormData();
    formData.append("departmentImage", file);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("Url", Url);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("status", status);
    formData.append("languege", languege);
    formData.append("category", category);

    try {
      const options = {
        onUploadProgress: (ProgressEvent) => {
          const { loaded, total } = ProgressEvent;
          let percent = Math.floor((loaded * 100) / total);
          console.log(`${loaded}kb of ${total}kb | ${percent}% `);
          dispatch({
            type: UPLOAD_PROGRESS,
            payload: { loaded, total, percent },
          });
          if (percent === 100) {
            setTimeout(() => dispatch({ type: REMOVE_PROGRESS }), 5000);
          }
        },
      };
      const res = await axios.post("/api/department", formData, options);
      dispatch({
        type: ADD_SCHOOL,
        payload: res.data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: ERROR_SCHOOL,
        payload: {
          msg: error.response.statusText,
          status: error.response.status,
        },
      });
    }
  };

export const updateSchool = (formData) => async (dispatch) => {
  const config = { headers: { "Content-Type": "application/json" } };
  try {
    const res = await axios.put(
      `/api/department/${formData.id}`,
      formData,
      config
    );
    dispatch({
      type: UPDATE_SCHOOL,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_SCHOOL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};

export const DeleteSchool = (id) => async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await axios.delete(`/api/department/${id}`);
    dispatch({
      type: DELETE_SCHOOL,
      payload: id,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_SCHOOL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};
