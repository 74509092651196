/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Progress } from "reactstrap";

const SurveyQuota = ({ title, data, P }) => {
  return (
    <div className="mb-4">
      <p className="mb-2">{title}</p>
      <Progress multi className="mb-3">
        <Progress
          key={`survey_progress_${1}`}
          bar
          style={{
            backgroundColor: P < 50 ? "red" : P < 75 ? "orange" : "green",
          }}
          value={(P / 100) * 100}
        />
      </Progress>
      <table className="table table-sm table-borderless">
        <tbody>
          <tr key="survey_data_1">
            <td className="p-0 pb-1 text-right">
              <span className="font-weight-medium text-muted text-small">
                {data}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(SurveyQuota);
