/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Progress } from "reactstrap";

const SurveyQuota = ({ data, title }) => {
  // const reducer = (accumulator, currentValue) => accumulator + currentValue;
  // const totalCount = data.map((x) => x.count).reduce(reducer);
  return (
    <div
      className="mb-4"
      style={{ lineHeight: "0.5rem", marginBottom: "1rem" }}
    >
      <p className="mb-2">
        {title} ({data.result}) {data.isEquation && "/ (معادلة)"}
      </p>
      <Progress multi className="mb-3">
        <Progress
          key={`survey_progress_${1}`}
          bar
          style={{
            backgroundColor:
              data.totalMarks < 50
                ? "red"
                : data.totalMarks < 75
                ? "orange"
                : "green",
          }}
          value={(data.totalMarks / 100) * 100}
        />
      </Progress>
      <table className="table table-sm table-borderless">
        <tbody>
          <tr key="survey_data_1">
            <td className="p-0 pb-1 w-10">
              <span
                className={`log-indicator border-theme-${
                  data.midtermExam > 30 ? 1 : data.midtermExam > 20 ? 3 : 2
                } align-middle`}
              />
            </td>
            <td className="p-0 pb-1 text-right">
              <span className="font-weight-medium text-muted text-small">{`
              ${data.midtermExam}/40
              
              نصفي`}</span>
            </td>
          </tr>
          <tr key="survey_data_1">
            <td className="p-0 pb-1 w-10">
              <span
                className={`log-indicator border-theme-${
                  data.finalExam > 50 ? 1 : data.finalExam > 30 ? 3 : 2
                } align-middle`}
              />
            </td>
            <td className="p-0 pb-1 text-right">
              <span className="font-weight-medium text-muted text-small">{`${data.finalExam}/60
              
              نهائي`}</span>
            </td>
          </tr>
          <tr key="survey_data_1">
            <td className="p-0 pb-1 w-10">
              <span
                className={`log-indicator border-theme-${
                  data.totalMarks > 80 ? 1 : data.totalMarks > 50 ? 3 : 2
                } align-middle`}
              />
            </td>
            <td className="p-0 pb-1 text-right">
              <span className="font-weight-medium text-muted text-small">{`${data.totalMarks}/100
               المجموع`}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default React.memo(SurveyQuota);
