import React from "react";
import Spinner from "../../components/Layout/Spinner";
import EditStudyYear from "../../components/modals/studyYears/EditStudyYear";
import DeleteStudyYear from "../../components/modals/studyYears/DeleteStudyYear";
import moment from "moment";
import { Tag } from "antd";
import { useSelector } from "react-redux";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import SaveStudyYearToArc from "../../components/modals/studyYears/SaveStudyYearToArc";

const CourseItem = ({ year }) => {
  const { _id, nameOfStudyYear, startOfStudyYear, endOfStudyYear, active } =
    year;
  const { user } = useSelector((state) => state.auth);
  return !year ? (
    <Spinner />
  ) : (
    <tr>
      <td className="right-align">{nameOfStudyYear}</td>
      <td className="right-align">
        {moment(startOfStudyYear).format("YYYY-MM-DD")}
      </td>
      <td className="right-align">
        {moment(endOfStudyYear).format("YYYY-MM-DD")}
      </td>
      <td className="right-align">
        {active ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            نشط
          </Tag>
        ) : (
          <Tag icon={<ClockCircleOutlined />} color="error">
            ارشيف
          </Tag>
        )}
      </td>
      {/* <td className="right-align">
      {user.Admin && 
      <>
      <DeleteStudyYear id={_id} />
      <EditStudyYear corrntStudyYear={year} />
      { active && <SaveStudyYearToArc id={_id} /> }
      </>
      }
      </td> */}
    </tr>
  );
};

export default CourseItem;
