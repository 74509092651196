import React, { useState } from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";
import { uploadResult } from "../../../actions/Student.action";
import { setAlert } from "../../../actions/alert";
import { CheckCircleTwoTone } from "@ant-design/icons";

const UploadResult = ({ uploadResult, error, setAlert, department }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [Image, setImage] = useState("");

  const onChangefile = (e) => {
    if (
      e.target.files[0].type === "text/csv" ||
      e.target.files[0].type === "application/vnd.ms-excel"
    ) {
      setImage(e.target.files[0]);
    } else {
      setAlert("نوع الملف غير مطابق  يجب ان يكون مطابق  csv", "danger");
      setIsModalVisible(false);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (Image && Image !== "") {
      uploadResult(Image);
      setAlert("تم تحميل الملف بنجاح", "success");
      setImage("");
    } else {
      setAlert(" يجب تحميل الملف ", "danger");
    }
    setIsModalVisible(false);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <a onClick={showModal}>
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          style={{ fontSize: "20px" }}
        />
      </a>
      <Modal
        title="تحميل ملف النتيجة"
        visible={isModalVisible}
        footer={[
          <Button key="back" onClick={handleCancel}>
            إلغاء الامر
          </Button>,
          <Button key="submit" type="primary" onClick={onSubmit}>
            تحميل
          </Button>,
        ]}
      >
        <label htmlFor="file"> ادخل الملف</label>
        <br />
        <input id="file" type="file" onChange={onChangefile} />
      </Modal>
    </>
  );
};
export default connect(null, {
  uploadResult,
  setAlert,
})(UploadResult);
