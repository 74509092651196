import React, { useState } from "react";
import { Modal, Button, Select } from "antd";
import { connect, useSelector } from "react-redux";
import { addCourse } from "../../../actions/Course.action";
import { setAlert } from "../../../actions/alert";
const { Option } = Select;

const AddCourse = ({ addCourse, error, setAlert, schoolId, studyYear }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const subjects = useSelector((state) => state.subjects);
  const classes = useSelector((state) => state.classes);
  const teachers = useSelector((state) => state.members);

  const [course, setCourse] = useState({
    subject: "",
    teacher: "",
    Class: "",
    school: schoolId,
  });

  const { subject, teacher, Class, school } = course;

  const onSubmit = async (e) => {
    if (subject !== "" && teacher !== "" && Class !== "") {
      console.log(course);
      addCourse(subject, teacher, Class, school, studyYear);
      setAlert("تم إضافة المقرر الدراسي بنجاح", "success");
      setCourse({
        subject: "",
        teacher: "",
        Class: "",
        school,
      });
    } else {
      setAlert(" يجب اختيار و ملء كل الحقول   ", "danger");
    }
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        اضافة مادة
      </Button>
      <Modal
        title="اضافة مقرر نشط"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            إلغاء الامر
          </Button>,
          <Button key="submit" type="primary" onClick={onSubmit}>
            اضافة
          </Button>,
        ]}
      >
        <h4>إختر الفصل</h4>
        <Select
          placeholder="إختر الفصل"
          style={{ width: "100%" }}
          onChange={(e) => setCourse({ ...course, Class: e })}
          loading={classes.loading}
          name="Class"
          value={Class}
        >
          {classes &&
            classes.classes.map((classy) => (
              <Option value={classy._id}>
                {classy.NumberOfClass + classy.letterOfClass}
              </Option>
            ))}
        </Select>

        <>
          <h4>إختر مقرر من الخطة الدراسية</h4>
          <Select
            style={{ width: "100%" }}
            onChange={(e) => setCourse({ ...course, subject: e })}
            loading={subjects.loading}
            name="subject"
            value={subject}
          >
            {subjects &&
              subjects.subjects.map((subject) => (
                <Option value={subject._id}>
                  {subject.subjectname} - {subject.year}
                </Option>
              ))}
          </Select>

          <h4>إختر استاذ مقرر</h4>

          <Select
            placeholder="إختر استاذ مقرر"
            style={{ width: "100%" }}
            onChange={(e) => setCourse({ ...course, teacher: e })}
            loading={teachers.loading}
            name="teacher"
            value={teacher}
          >
            {teachers &&
              teachers.members.map((teacher) => (
                <Option value={teacher._id}>{teacher.name}</Option>
              ))}
          </Select>
        </>
      </Modal>
    </>
  );
};
export default connect(null, {
  addCourse,
  setAlert,
})(AddCourse);
