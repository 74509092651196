import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { updateCourse } from "../../../actions/Course.action";
import { setAlert } from "../../../actions/alert";
import { getMembers } from "../../../actions/Members.actions";
import { Modal, Button, Select } from "antd";
import { FormOutlined } from "@ant-design/icons";
const { Option } = Select;
const EditCourse = ({ correntCourse, updateCourse, getMembers, setAlert }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [course, setCourse] = useState({
    id: correntCourse._id,
    subject: correntCourse.subject._id,
    subjectname: correntCourse.subject.subjectname,
    teacher: correntCourse.teacher._id,
    teacherName: correntCourse.teacher.name,
    NumberOfClass: correntCourse.Class && correntCourse.Class.NumberOfClass,
    letterOfClass: correntCourse.Class && correntCourse.Class.letterOfClass,
    Class: correntCourse.Class && correntCourse.Class._id,
  });
  const subjects = useSelector((state) => state.subjects);
  const classes = useSelector((state) => state.classes);
  const teachers = useSelector((state) => state.members);

  const { id, subject, teacher, Class } = course;

  const onSubmit = async (e) => {
    e.preventDefault();
    updateCourse({ id, subject, teacher, Class });
    setAlert("تم التعديل على بيانات هذا المقرر", "success");
    setIsModalVisible(false);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <a onClick={showModal}>
        <FormOutlined />
      </a>
      <Modal
        title="تعديل علي مقرر"
        visible={isModalVisible}
        footer={[
          <Button key="back" onClick={handleCancel}>
            إلغاء الامر
          </Button>,
          <Button key="submit" type="primary" onClick={onSubmit}>
            تعديل
          </Button>,
        ]}
      >
        <h4>إختر مقرر من الخطة الدراسية</h4>
        <Select
          style={{ width: "100%" }}
          onChange={(e) => setCourse({ ...course, subject: e })}
          loading={subjects.loading}
          name="subject"
          value={subject}
        >
          {subjects &&
            subjects.subjects.map((subject) => (
              <Option value={subject._id}>{subject.subjectname}</Option>
            ))}
        </Select>
        <h4>إختر الفصل</h4>
        <Select
          placeholder="إختر الفصل"
          style={{ width: "100%" }}
          onChange={(e) => setCourse({ ...course, Class: e })}
          loading={classes.loading}
          name="Class"
          value={Class}
        >
          {classes &&
            classes.classes.map((classy) => (
              <Option value={classy._id}>
                {classy.NumberOfClass + classy.letterOfClass}
              </Option>
            ))}
        </Select>
        <h4>إختر استاذ المقرر</h4>

        <Select
          placeholder="إختر استاذ المقرر"
          style={{ width: "100%" }}
          onChange={(e) => setCourse({ ...course, teacher: e })}
          loading={teachers.loading}
          name="teacher"
          value={teacher}
        >
          {teachers &&
            teachers.members.map((teacher) => (
              <Option value={teacher._id}>{teacher.name}</Option>
            ))}
        </Select>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  members: state.members,
});
export default connect(mapStateToProps, {
  updateCourse,
  getMembers,
  setAlert,
})(EditCourse);
