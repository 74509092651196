import React from "react";

import PropTypes from "prop-types";

import Spinner from "../../components/Layout/Spinner";
import EditCourse from "../../components/modals/courses/EditCourse";
import DeleteCourse from "../../components/modals/courses/DeleteCourse";

import { Avatar, Image } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const CourseItem = ({ course }) => {
  const { _id, image, topics, teacher, Class, subject } = course;
  const { Url } = useSelector((state) => state.schools.school);

  return !course ? (
    <Spinner />
  ) : (
    <tr>
      <td className="right-align">
        <Avatar shape="square" src={<Image src={image} />} />
      </td>
      <td className="right-align">
        <Link to={`/teacher/${Url}/courses/${course._id}`} id="courseitem">
          {" "}
          {subject.subjectname} -{" "}
          {Class && `${Class.NumberOfClass}${Class.letterOfClass}`}{" "}
        </Link>
      </td>
      <td className="right-align">{topics && topics.length}</td>
      <td className="right-align">{teacher.name}</td>
      <td className="right-align">
        <DeleteCourse id={_id} />
        <EditCourse correntCourse={course} />
      </td>
    </tr>
  );
};

CourseItem.propTypes = {
  research: PropTypes.object.isRequired,
};

export default CourseItem;
