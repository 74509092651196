/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
import React, { useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { Col, Row } from "antd";
import { Colxx } from "./CustomBootstrap";

import CGP from "./CGP";
import SurveyQuota from "./SurveyQuota";

import { useSelector, useDispatch } from "react-redux";
import { getStudentSemesterResult } from "../../actions/Student.action";
import Container from "./Container";

const SurveyQuotas = ({}) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStudentSemesterResult(user._id));
  }, []);
  const { semResult } = useSelector((state) => state.students);

  const sem1 =
    semResult.length > 0 && [...semResult].find((r) => r.year === "1");
  const sem2 =
    semResult.length > 0 && [...semResult].find((r) => r.year === "2");
  const sem3 =
    semResult.length > 0 && [...semResult].find((r) => r.year === "3");
  const sem4 =
    semResult.length > 0 && [...semResult].find((r) => r.year === "4");
  const sem5 =
    semResult.length > 0 && [...semResult].find((r) => r.year === "5");
  const sem6 =
    semResult.length > 0 && [...semResult].find((r) => r.year === "6");

  return (
    <Container>
      <Col span={24}>
        {sem1 && (
          <Card className="mb-4">
            <CardBody>
              <h2 className="list-item-heading mb-4 ">الفصل الاول</h2>
              <Row>
                {sem1.results &&
                  sem1.results.map((res, index) => (
                    <Col
                      className="gutter-row p-1"
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      xl={6}
                      key={`quota_${index}`}
                    >
                      <SurveyQuota
                        title={res.subject && res.subject.subjectname}
                        data={res}
                      />
                    </Col>
                  ))}
              </Row>

              <Row>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="المعدل الفصلي" data={sem1.GPA} P={sem1.GPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="التقدير الفصلي" data={sem1.grade} P={sem1.GPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="المعدل التراكمي" data={sem1.CGPA} P={sem1.CGPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP
                    title="التقدير التراكمي"
                    data={sem1.CGrade}
                    P={sem1.CGPA}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {sem2 && (
          <Card className="mb-4">
            <CardBody>
              <h2 className="list-item-heading mb-4 ">الفصل الثاني</h2>
              <Row>
                {sem2.results &&
                  sem2.results.map((res, index) => (
                    <Col
                      className="gutter-row p-1"
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      xl={6}
                      key={`quota_${index}`}
                    >
                      <SurveyQuota
                        title={res.subject && res.subject.subjectname}
                        data={res}
                      />
                    </Col>
                  ))}
              </Row>

              <Row>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="المعدل الفصلي" data={sem2.GPA} P={sem2.GPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="التقدير الفصلي" data={sem2.grade} P={sem2.GPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="المعدل التراكمي" data={sem2.CGPA} P={sem2.CGPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP
                    title="التقدير التراكمي"
                    data={sem2.CGrade}
                    P={sem2.CGPA}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {sem3 && (
          <Card className="mb-4">
            <CardBody>
              <h2 className="list-item-heading mb-4 ">الفصل الثالث</h2>
              <Row>
                {sem3.results &&
                  sem3.results.map((res, index) => (
                    <Col
                      className="gutter-row p-1"
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      xl={6}
                      key={`quota_${index}`}
                    >
                      <SurveyQuota
                        title={res.subject && res.subject.subjectname}
                        data={res}
                      />
                    </Col>
                  ))}
              </Row>

              <Row>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="المعدل الفصلي" data={sem3.GPA} P={sem3.GPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="التقدير الفصلي" data={sem3.grade} P={sem3.GPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="المعدل التراكمي" data={sem3.CGPA} P={sem3.CGPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP
                    title="التقدير التراكمي"
                    data={sem3.CGrade}
                    P={sem3.CGPA}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {sem4 && (
          <Card className="mb-4">
            <CardBody>
              <h2 className="list-item-heading mb-4 ">الفصل الرابع</h2>
              <Row>
                {sem4.results &&
                  sem4.results.map((res, index) => (
                    <Col
                      className="gutter-row p-1"
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      xl={6}
                      key={`quota_${index}`}
                    >
                      <SurveyQuota
                        title={res.subject && res.subject.subjectname}
                        data={res}
                      />
                    </Col>
                  ))}
              </Row>

              <Row>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="المعدل الفصلي" data={sem4.GPA} P={sem4.GPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="التقدير الفصلي" data={sem4.grade} P={sem4.GPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="المعدل التراكمي" data={sem4.CGPA} P={sem4.CGPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP
                    title="التقدير التراكمي"
                    data={sem4.CGrade}
                    P={sem4.CGPA}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {sem5 && (
          <Card className="mb-4">
            <CardBody>
              <h2 className="list-item-heading mb-4 ">الفصل الخامس</h2>
              <Row>
                {sem5.results &&
                  sem5.results.map((res, index) => (
                    <Col
                      className="gutter-row p-1"
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      xl={6}
                      key={`quota_${index}`}
                    >
                      <SurveyQuota
                        title={res.subject && res.subject.subjectname}
                        data={res}
                      />
                    </Col>
                  ))}
              </Row>

              <Row>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="المعدل الفصلي" data={sem5.GPA} P={sem5.GPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="التقدير الفصلي" data={sem5.grade} P={sem5.GPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="المعدل التراكمي" data={sem5.CGPA} P={sem5.CGPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP
                    title="التقدير التراكمي"
                    data={sem5.CGrade}
                    P={sem5.CGPA}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {sem6 && (
          <Card className="mb-4">
            <CardBody>
              <h2 className="list-item-heading mb-4 ">الفصل السادس</h2>
              <Row>
                {sem6.results &&
                  sem6.results.map((res, index) => (
                    <Col
                      className="gutter-row p-1"
                      xs={24}
                      sm={12}
                      md={8}
                      lg={6}
                      xl={6}
                      key={`quota_${index}`}
                    >
                      <SurveyQuota
                        title={res.subject && res.subject.subjectname}
                        data={res}
                      />
                    </Col>
                  ))}
              </Row>
              <Row>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="المعدل الفصلي" data={sem6.GPA} P={sem6.GPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="التقدير الفصلي" data={sem6.grade} P={sem6.GPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP title="المعدل التراكمي" data={sem6.CGPA} P={sem6.CGPA} />
                </Col>
                <Col
                  className="gutter-row p-1"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                >
                  <CGP
                    title="التقدير التراكمي"
                    data={sem6.CGrade}
                    P={sem6.CGPA}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Col>
    </Container>
  );
};

export default SurveyQuotas;
