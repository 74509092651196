import React, { useState } from "react";
import { Modal, Button, Select } from "antd";
import FormInput from "../../form-input/form-input.component";
import { connect } from "react-redux";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addSchool } from "../../../actions/school.action";
import { setAlert } from "../../../actions/alert";
import { PlusCircleOutlined } from "@ant-design/icons";
const { Option } = Select;

const AddSchool = ({ addSchool, setAlert, color }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [School, setSchool] = useState({
    title: "",
    description: "",
    Url: "",
    languege: "",
    email: "",
    phone: "",
    status: "",
    category: "",
  });

  const { title, description, Url, languege, email, phone, status, category } =
    School;

  const [file, setFile] = useState("");

  const onChangefile = (e) => {
    setFile(e.target.files[0]);
  };
  const onChange = (e) => {
    setSchool({ ...School, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (title === "" || description === "" || file === "") {
      setAlert("ارجو تعبئة كل الحقول و تحميل الصور لهدا القسم", "danger");
    } else {
      addSchool(
        file,
        title,
        description,
        Url,
        languege,
        email,
        phone,
        status,
        category
      );
      setAlert(" تم إضافة القسم  بنجاح", "success");
      setSchool({
        title: "",
        description: "",
        Url: "",
        languege: "",
        email: "",
        phone: "",
        status: "",
        price: "",
        category: "",
      });
    }
    setIsModalVisible(false);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <a onClick={showModal} className={`text-${color}`}>
        <PlusCircleOutlined />
      </a>
      <Modal
        title="إضافة قسم او حقيبة تدريبية"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            إلغاء الامر
          </Button>,
          <Button key="submit" type="primary" onClick={onSubmit}>
            إضافة
          </Button>,
        ]}
      >
        <br />
        <h4 className="my-1">إختر نوع الاضافة</h4>
        <Select
          style={{ width: "100%" }}
          onChange={(e) => setSchool({ ...School, category: e })}
          name="category"
        >
          <Option value="">إختر نوع الاضافة </Option>
          <Option value="department">إضافة قسم </Option>
          <Option value="center">إضافة مركز </Option>
          <Option value="training">إضافة حقيبة تدريبية </Option>
          <Option value="school">إضافة مدرسة </Option>
        </Select>
        <input id="file" type="file" onChange={onChangefile} />
        <FormInput
          name="title"
          type="text"
          handleChange={onChange}
          value={title}
          label="العنوان"
          required
        />
        <FormInput
          name="Url"
          type="text"
          handleChange={onChange}
          value={Url}
          label="الرمز  باللغة الانجليزية"
          required
        />
        <FormInput
          name="languege"
          type="text"
          handleChange={onChange}
          value={languege}
          label="لغة التدريس"
          required
        />
        <FormInput
          name="email"
          type="text"
          handleChange={onChange}
          value={email}
          label="البريد الالكتروني"
          required
        />
        <FormInput
          name="phone"
          type="text"
          handleChange={onChange}
          value={phone}
          label="رقم الهاتف"
          required
        />
        <label htmlFor="firstName" className="active">
          عن
        </label>
        <CKEditor
          editor={ClassicEditor}
          data={description}
          onChange={(event, editor) => {
            const data = editor.getData();
            setSchool({ ...School, description: data });
          }}
        />
      </Modal>
    </>
  );
};

export default connect(null, {
  addSchool,
  setAlert,
})(AddSchool);
