import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../components/Layout/Spinner";
import { getSchools } from "../../actions/school.action";
import AddSchool from "../../components/modals/School/AddSchool";
import { Divider } from "antd";
import Container from "./Container";
import StudyYearsPage from "../../dashBourd/StudyYears/StudyYears.page";
import StudentPage from "../../dashBourd/Students/AllStudent.page";
import DepartmentItems from "./departmentItems";

const Schools = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSchools());
    // eslint-disable-next-line
  }, []);
  const schooles = useSelector((state) => state.schools);
  const user = useSelector((state) => state.auth.user);
  const { loading, schools } = schooles;

  return loading ? (
    <Spinner />
  ) : (
    <>
      <Container>
        <div className="container-fluid">
          {schools.length > 0 ? null : (
            <Divider orientation="left">
              <h1 className={`text-primary`}>
                {"تكوين قسم او مركز"}{" "}
                {user && user.Admin && (
                  <>
                    <AddSchool color="primary" />
                  </>
                )}
              </h1>
            </Divider>
          )}

          <DepartmentItems
            user={user}
            schools={schools}
            category="training"
            title="الحقائب التدريبية"
            color="primary"
          />
          <DepartmentItems
            user={user}
            schools={schools}
            category="department"
            title="الاقسام"
            color="success"
          />
          <DepartmentItems
            user={user}
            schools={schools}
            category="center"
            color="warning"
            title="المراكز"
          />
          <DepartmentItems
            user={user}
            schools={schools}
            category="school"
            title="المدارس"
            color="danger"
          />
          <StudyYearsPage />
        </div>
        <br />
      </Container>
    </>
  );
};

export default Schools;
