import {
  GET_RESULTS,
  GET_STUDENTS,
  ADD_STUDENT,
  ERROR_STUDENT,
  UPDATE_STUDENT,
  DELETE_STUDENT,
  FILTER_STUDENTS,
} from "../actions/types";

const inialState = {
  alumni: [],
  students: [],
  semResult: [],
  student: null,
  filtered: [],
  loading: true,
  error: {},
};

export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_STUDENTS:
      return {
        ...state,
        students: payload.filter((al) => al.alumni !== "true"),
        alumni: payload.filter((al) => al.alumni === "true"),
        loading: false,
      };
    case GET_RESULTS:
      return {
        ...state,
        semResult: payload,
      };
    case FILTER_STUDENTS:
      return {
        ...state,
        filtered: state.students.filter((student) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            student.name.match(regex) ||
            student.department.match(regex) ||
            student.sid.toString().match(regex)
          );
        }),
      };
    case ADD_STUDENT:
      return {
        ...state,
        students: [...state.students, payload],
        loading: false,
      };
    case UPDATE_STUDENT:
      return {
        ...state,
        filtered:
          state.filtered &&
          state.filtered.map((student) =>
            student._id === payload._id ? payload : student
          ),
        students: state.students.map((student) =>
          student._id === payload._id ? payload : student
        ),
        loading: false,
      };
    case DELETE_STUDENT:
      return {
        ...state,
        students: state.students.filter((student) => student._id !== payload),
        loading: false,
      };
    case ERROR_STUDENT:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
