import React, { useEffect } from "react";
import { Tabs } from "antd";
import Container from "./Container";
import { useSelector } from "react-redux";
import MembersPage from "../../../dashBourd/Members/Members.page";
import StudentPage from "../../../dashBourd/Students/Student.page";

const { TabPane } = Tabs;

const Demo = () => {
  const { school } = useSelector((state) => state.schools);

  return (
    <Container>
      <Tabs defaultActiveKey="1" centered style={{ direction: "ltr" }}>
        <TabPane tab="المسؤولين" key="3">
          <MembersPage admin={true} />
        </TabPane>
        <TabPane tab="الطلبة" key="2">
          <StudentPage />
        </TabPane>
        <TabPane
          tab={
            school && school.category === "department"
              ? "اعضاء هيئة التدريس"
              : school.category === "school"
              ? "المعلمين"
              : "المدربين"
          }
          key="1"
        >
          <MembersPage />
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default Demo;
