import React from "react";
import Spinner from "../../../components/Layout/Spinner";
import Container from "./Container";
import { useSelector } from "react-redux";
import { Timeline } from "antd";
import EditSchool from "../../../components/modals/School/EditSchool";

const SchoolDetails = ({ match }) => {
  const { school, loading } = useSelector((state) => state.schools);
  return (
    <Container url={match.params.url}>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {school && school.category === "department" && <h2>بيانات القسم </h2>}
          {school && school.category === "center" && <h2>بيانات المركز </h2>}
          {school && school.category === "training" && (
            <h2>بيانات الحقيبة التدريبية </h2>
          )}
          {school && school.category === "school" && <h2>بيانات المدرسة </h2>}
          <br />
          <Timeline mode="right">
            <Timeline.Item>العنوان : {school && school.title}</Timeline.Item>
            <Timeline.Item>الرمز : {school && school.Url}</Timeline.Item>
            <Timeline.Item>رمز الانتساب : {school && school._id}</Timeline.Item>
            <Timeline.Item>
              {" "}
              لغة الدراسة : {school && school.languege}
            </Timeline.Item>
            <Timeline.Item>
              {" "}
              البريد الالكنروني : {school && school.email}
            </Timeline.Item>
            <Timeline.Item>
              {" "}
              رقم الهاتف : {school && school.phone}
            </Timeline.Item>
            <h3> عن </h3>
            <Timeline.Item>
              <p
                dangerouslySetInnerHTML={{
                  __html: school && school.description,
                }}
              ></p>
            </Timeline.Item>

            {school && <EditSchool correntSchool={school} />}
          </Timeline>
        </div>
      )}
    </Container>
  );
};

export default SchoolDetails;
