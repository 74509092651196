import React, { Fragment } from "react";
import { connect } from "react-redux";
import EditStudent from "../../components/modals/students/EditStudent";
import DeleteStudent from "../../components/modals/students/DeleteStudent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

import ResetPassword from "../../components/modals/students/ResetPassword";
const StudentItem = ({ student, isAuthenticated, user, Class }) => {
  const {
    _id,
    nid,
    name,
    fatherName,
    surename,
    nationality,
    adress,
    departmentName,
    sid,
    email,
    Blocking,
  } = student;

  const menu = (
    <Menu>
      <Menu.Item>
        <EditStudent correntStudent={student} />
      </Menu.Item>
      {/* <Menu.Item>
        <DeleteStudent id={_id} />
      </Menu.Item> */}
    </Menu>
  );
  return (
    <tr>
      <td className="right-align">{nid}</td>
      <td className="right-align">
        {name} {fatherName} {surename}
      </td>
      <td className="right-align">{sid}</td>
      <td className="right-align">{nationality}</td>
      <td className="right-align">{adress}</td>
      <td className="right-align">{departmentName}</td>
      <td className="right-align">{email}</td>

      <td className="right-align">
        {!Class && (
          <Dropdown overlay={menu}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              المزيد
              <DownOutlined />
            </a>
          </Dropdown>
        )}
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps)(StudentItem);
