import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import { getStudents } from "../../actions/Student.action";
import AddStudent from "../../components/modals/students/SignUpStudent";
import StudentItems from "./StudentItems";
import StudentFilter from "../../components/students/StudentFilter";
import Spinner from "../../components/Layout/Spinner";
import { getSubjects } from "../../actions/Subjects.action";
import UploadStudents from "../../components/modals/students/uploadAlumni";
import UploadResult from "../../components/modals/students/uploadResult";

const StudentsPage = ({
  getStudents,
  school,
  students: { students, filtered, loading },
  Class,
  user,
}) => {
  useEffect(() => {
    getStudents();
    // eslint-disable-next-line
  }, [loading]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const onShowSizeChange = (current = 1, pageSize) => {
    setPageSize(pageSize);
    setCurrentPage(currentPage);
    console.log(current);
  };

  const onChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container-fluid" style={{ direction: "rtl" }}>
        <UploadStudents type="student" /> {"  "}
        <UploadResult />
        {user.departments &&
          user.departments.length > 0 &&
          user.departments.map(
            (s) =>
              s.id._id.toString() === school._id.toString() &&
              s.roll === "admin" && <AddStudent schoolId={school._id} />
          )}
        <table className="striped container-fluid ">
          <thead>
            <tr>
              <th className="right-align">الرقم الوطني</th>
              <th className="right-align"> الاسم</th>
              <th className="right-align"> رقم القيد</th>
              <th className="right-align"> الجنسية</th>
              <th className="right-align"> عنوان السكن</th>
              <th className="right-align"> القسم</th>
              <th className="right-align"> البريد الالكتروني</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {filtered.length > 0
              ? filtered.map((student) => (
                  <Fragment key={student && student._id}>
                    <StudentItems student={student} Class={Class} />
                  </Fragment>
                ))
              : students &&
                students.map((student) => (
                  <Fragment key={student && student._id}>
                    <StudentItems student={student} Class={Class} />
                  </Fragment>
                ))}
          </tbody>
        </table>
        <div className="center" style={{ direction: "ltr" }}>
          <Pagination
            pageSizeOptions={[10, 20, 30, students.length]}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            defaultCurrent={2}
            current={currentPage}
            defaultPageSize={10}
            onChange={onChange}
            total={students.length}
          />
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  students: state.students,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  school: state.schools.school,
});
export default connect(mapStateToProps, {
  getSubjects,
  getStudents,
})(StudentsPage);
