import React from "react";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import DeleteSchool from "../../components/modals/School/DeleteSchool";
import EditSchool from "../../components/modals/School/EditSchool";
import AddAdminToSchool from "../../components/modals/School/AddAdminToSchool";
import { Link } from "react-router-dom";
import { clearData } from "../../actions/school.action";
import { useDispatch } from "react-redux";

const SchoolItems = ({ school }) => {
  const dispatch = useDispatch();
  const { Url, title, languege, phone, _id } = school;
  const menu = (
    <Menu style={{ textAlign: "right" }}>
      {/* <Menu.Item key="0">
        <AddAdminToSchool schoolID={_id} />
      </Menu.Item> */}
      <Menu.Item key="1">
        <EditSchool correntSchool={school} />
      </Menu.Item>
      <Menu.Item key="2">
        <Link to={`/department/${Url}`} onClick={() => dispatch(clearData())}>
          المزيد
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" danger>
        <DeleteSchool id={_id} />
      </Menu.Item>
    </Menu>
  );
  return (
    <tr>
      <td className="right-align">{Url}</td>
      <td className="right-align">{title}</td>
      <td className="right-align"> {phone}</td>
      <td className="right-align"> {languege}</td>
      <td className="right-align"> {_id}</td>
      <td className="right-align">
        <Dropdown overlay={menu} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            خيارات <DownOutlined />
          </a>
        </Dropdown>
      </td>
    </tr>
  );
};

export default SchoolItems;
