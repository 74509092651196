import React, { useEffect } from "react";
import "./Home.styles.scss";
import { connect, useDispatch, useSelector } from "react-redux";
import UserInfo from "./homeComponents/userInfo";
import Spinner from "../../../components/Layout/Spinner";
import Container from "./Container";
import { Link } from "react-router-dom";
import AffiliationToSchool from "../../../components/modals/Members/AffiliationToSchool";
import { clearData, getSchools } from "../../../actions/school.action";

import { Row, Col } from "antd";
const Home = ({ user }) => {
  const dispatch = useDispatch();

  return !user ? (
    <Spinner />
  ) : (
    <Container>
      <Row gutter={16}>
        <Col md={8} sm={24}>
          <UserInfo user={user} />
        </Col>
        <Col md={16} sm={24}>
          <div className="text-center my-3">
            <h3 className="my-2 ">الاقسام</h3>
            {user && (
              <>
                {user.departments &&
                  user.departments.length > 0 &&
                  user.departments.map((department, i) => (
                    <>
                      {department.roll === "admin" && (
                        <div className="my-2">
                          <Link
                            onClick={() => dispatch(clearData())}
                            className="card-link"
                            to={`/department/${department.id.Url}`}
                          >
                            الدخول كمسؤول للوحة التحكم في {department.id.title}
                          </Link>
                        </div>
                      )}

                      <div className="my-2">
                        <Link
                          className="card-link"
                          to={`/teacher/${department.id.Url}`}
                        >
                          الدخول كآستاذ للوحة التحكم في {department.id.title}
                        </Link>
                      </div>
                    </>
                  ))}
              </>
            )}

            <AffiliationToSchool id={user._id} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStatetoProps = (state) => ({
  user: state.auth.user,
  tss: state.tss.tss,
});
export default connect(mapStatetoProps)(Home);
