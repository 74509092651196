import React from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

import BlockingMember from "../../components/modals/Members/BlockingMember";
import DeleteMember from "../../components/modals/Members/DeleteTeacher";
import AddAdmin from "../../components/modals/Members/AddAdmintoSchool";
import RemoveAdmin from "../../components/modals/Members/RemoveAdminFromSchool";

import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import ResetPassword from "../../components/modals/Members/ResetPassword";

const MembersItem = ({ member, index, isAuthenticated, user, admin }) => {
  const { _id, nid, name, nationality, adress, email, phone } = member;
  const { school } = useSelector((state) => state.schools);

  const menu = (
    <Menu style={{ textAlign: "right" }}>
      {/* <Menu.Item>
         <BlockingMember id={_id} Blocking={Blocking} name={name} />
      </Menu.Item> */}
      {admin ? (
        <Menu.Item>
          <RemoveAdmin id={_id} schoolID={school._id} />
        </Menu.Item>
      ) : (
        <Menu.Item>
          <AddAdmin id={_id} schoolID={school._id} />
        </Menu.Item>
      )}
      <Menu.Item>
        <DeleteMember id={_id} />
      </Menu.Item>
      <Menu.Item>
        <ResetPassword id={_id} />
      </Menu.Item>
    </Menu>
  );

  return (
    <tr>
      <td className="right-align">{index + 1}</td>
      <td className="right-align">{nid}</td>
      <td className="right-align"> {name}</td>
      <td className="right-align"> {nationality}</td>
      <td className="right-align"> {adress}</td>
      <td className="right-align"> {email}</td>
      <td className="right-align"> {phone}</td>
      <td className="right-align">
        <Dropdown overlay={menu}>
          {/* eslint-disable-next-line */}
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            المزيد
            <DownOutlined />
          </a>
        </Dropdown>
      </td>
    </tr>
  );
};

MembersItem.propTypes = {
  member: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default connect(mapStateToProps)(MembersItem);
