import React, { Fragment, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import CourseItems from "./CoursesItems";
import { getCoursesByClassId } from "../../../actions/Course.action";
import Container from "../Container";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";

const CoursesPage = ({
  courses: { courses, loading },
  getCoursesByClassId,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { year } = useSelector((state) => state.years);
  const dispatch = useDispatch();
  useEffect(() => {
    user.classy && year && getCoursesByClassId(user.classy, year._id);

    // dispatch(getStudentResultList(user.id));

    // eslint-disable-next-line
  }, [loading]);

  return (
    <Container>
      <div id="courses-header">
        <h3>المواد الدراسية</h3>
      </div>
      <div id="course-body">
        <Row gutter={16}>
          {courses &&
            courses.map((course) => (
              <Fragment key={course._id}>
                <Col md={8} sm={24}>
                  <CourseItems course={course} />
                </Col>
              </Fragment>
            ))}
        </Row>
      </div>
    </Container>
  );
};

const mapStatetoProps = (state) => ({
  courses: state.courses,
});
export default connect(mapStatetoProps, { getCoursesByClassId })(CoursesPage);
