import React from "react";
import { Tabs } from "antd";
import Container from "./Container";
import ClassesPage from "../../../dashBourd/Classes/Class.page";
import CoursesPage from "../../../dashBourd/Courses/Courses.page";
import SubjectsPage from "../../../dashBourd/subjects/subjects";

const { TabPane } = Tabs;

const Demo = () => {
  return (
    <Container>
      <Tabs defaultActiveKey="1" centered style={{ direction: "ltr" }}>
        <TabPane tab="الخطة الدراسية" key="3">
          <SubjectsPage />
        </TabPane>
        <TabPane tab="المقررات الدراسية" key="2">
          <CoursesPage />
        </TabPane>
        <TabPane tab="الفصول والمجموعات " key="1">
          <ClassesPage />
        </TabPane>
      </Tabs>
    </Container>
  );
};

export default Demo;
