import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStudyYears } from "../../actions/stydyYear.action";
import StudyYearItems from "./StudyYearItems";

import AddStudyYear from "../../components/modals/studyYears/AddStudyYear";
import RemoveStudentfromClasses from "../../components/modals/studyYears/RemoveStudentfromClasses";
import Spinner from "../../components/Layout/Spinner";
import { Divider } from "antd";
const StudyYears = () => {
  const dispatch = useDispatch();
  const { years, loading } = useSelector((state) => state.years);
  const { school } = useSelector((state) => state.schools);

  useEffect(() => {
    dispatch(getStudyYears());
    // eslint-disable-next-line
  }, [loading]);
  const { user } = useSelector((state) => state.auth);
  const Disabled = years.find((year) => year.active);
  console.log(Disabled);
  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <Divider orientation="left">
        <h1>العام الجامعي</h1>
      </Divider>
      <div className="container-fluid"></div>
      <table className="striped container-fluid ">
        <thead>
          <tr>
            <th className="right-align"> العام الدراسي</th>
            <th className="right-align">بداية العام الدراسي</th>
            <th className="right-align"> نهاية العام الدراسي</th>
            <th className="right-align"> الحالة</th>
            <th className="right-align"></th>
          </tr>
        </thead>
        <tbody>
          {years &&
            years.map((year) => (
              <Fragment key={year._id}>
                <StudyYearItems year={year} />
              </Fragment>
            ))}
        </tbody>
      </table>
      <RemoveStudentfromClasses Disabled={Disabled && Disabled !== ""} />
    </Fragment>
  );
};

export default StudyYears;
