import React, { useRef, useEffect } from "react";
import { subjectFilterByName } from "../../actions/Subjects.action";
import { connect, useSelector } from "react-redux";
import { Input } from "antd";

const ContactFilter = ({ subjectFilterByName }) => {
  const { filtered } = useSelector((state) => state.subjects.filtered);
  useEffect(() => {
    if (filtered === null) {
      text.current.value = "";
    }
  });
  const text = useRef("");

  const onChange = (e) => {
    subjectFilterByName(e.target.value);
  };

  return (
    <Input
      className="filter-input"
      name="title"
      type="text"
      ref={text}
      onChange={onChange}
      placeholder=" للبحث ادخل اسم المقرر او الفصل الدراسي     ..."
    />
  );
};

export default connect(null, { subjectFilterByName })(ContactFilter);
